const calculateBidIncrement = () => {
	let increment = 500;
	// if (value >= 0 && value < 5) {
	// 	increment = 1;
	// } else if (value >= 5 && value < 40) {
	// 	increment = 5;
	// } else if (value >= 40 && value < 100) {
	// 	increment = 10;
	// } else if (value >= 100 && value < 1000) {
	// 	increment = 25;
	// } else if (value >= 1000 && value < 5000) {
	// 	increment = 50;
	// } else if (value >= 5000 && value < 25000) {
	// 	increment = 100;
	// } else if (value >= 25000 && value < 50000) {
	// 	increment = 250;
	// } else if (value >= 50000 && value < 100000) {
	// 	increment = 500;
	// } else {
	// 	increment = 1000;
	// }
	return increment;
};

const calculateBidDecrement = () => {
	let decrement = 500;
	// if (value <= 5) {
	// 	decrement = 1;
	// } else if (value <= 40) {
	// 	decrement = 5;
	// } else if (value <= 100) {
	// 	decrement = 10;
	// } else if (value <= 1000) {
	// 	decrement = 25;
	// } else if (value <= 5000) {
	// 	decrement = 50;
	// } else if (value <= 25000) {
	// 	decrement = 100;
	// } else if (value <= 50000) {
	// 	decrement = 250;
	// } else if (value <= 100000) {
	// 	decrement = 500;
	// } else {
	// 	decrement = 1000;
	// }
	return decrement;
};

export { calculateBidDecrement, calculateBidIncrement };
