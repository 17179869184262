import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyPusher from '../../App/config';
import { authAxios } from '../../axious-config';
import Button from '../../components/bootstrap/Button';
import {
	updateAddNotification,
	updateDeleteNotification,
	updateReadNotification,
} from '../../features/notification/notificationSlice';
import Alert from '../bootstrap/Alert';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';
import Spinner from '../bootstrap/Spinner';
import useAutoCloseToast from '../customHooks/useAutoCloseToast';

const NotificationComponent = ({ darkModeStatus }) => {
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const { accessToken } = useSelector((state) => state.auth);
	const { myNotifications } = useSelector((state) => state.notification);
	const [channel, setChannel] = useState(null);
	const [pusherInstance, setPusherInstance] = useState(null);
	const [notificationLoading, setNotificationLoading] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useAutoCloseToast();

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	useEffect(() => {
		if (accessToken) {
			const pusher = MyPusher(accessToken);
			// TODO:: pusher 403 status handle
			const pusher_channel = pusher.subscribe(`private-admin.notifications`);

			setChannel(pusher_channel);
			setPusherInstance(pusher);
		}

		return () => {
			if (pusherInstance && channel) {
				channel.unsubscribe();
				pusherInstance.disconnect();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const _handleNotification = (url, item) => {
		const postUrl = `notifications/${item.id}/mark-as-read`;

		if (!item.is_read) {
			authAxios.post(postUrl, null, {
				headers: {
					Authorization: 'Bearer ' + accessToken,
				},
			});
		}

		if (url) {
			navigate(url);
		}

		setOffcanvasStatus(false);
	};

	const handleNotifyClick = (notifyObject) => {
		let item = notifyObject?.notification;
		if (item) {
			let url = '';

			if (item?.notifiable_type == 'Member') {
				url = `/customer-settings/members/${item.notifiable_id}`;
			}

			if (item?.notifiable_type == 'Vehicle') {
				url = `/vehicles/${item.notifiable_id}`;
			}

			if (item?.notifiable_type == 'Auction') {
				url = `/auctions/${item.notifiable_id}`;
			}

			if (item?.notifiable_type == 'CustomerMessage') {
				url = `/customers-queries/${item.notifiable_id}`;
			}

			_handleNotification(url, item);
		}
	};

	useEffect(() => {
		if (channel && channel.bind) {
			channel.bind('NEW_NOTIFICATION', function (data) {
				toast.info(
					data?.notification?.message ? (
						<p className='' onClick={() => handleNotifyClick(data)}>
							{data?.notification?.message}
						</p>
					) : (
						'New notification arrived'
					),
					{
						position: 'top-right',
						autoClose: 4000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						theme: 'colored',
						toastId: data?.notification?.id,
					},
				);
				dispatch(updateAddNotification({ ...data }));
			});
			channel.bind('READ_NOTIFICATION', function (data) {
				dispatch(updateReadNotification({ ...data }));
			});
			channel.bind('DELETE_NOTIFICATION', function (data) {
				dispatch(updateDeleteNotification({ ...data }));
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channel]);

	const _handleMarkAsRead = () => {
		setNotificationLoading(true);
		const postUrl = 'notifications/mark-all-as-read';

		authAxios
			.post(postUrl, null, {
				headers: {
					Authorization: 'Bearer ' + accessToken,
				},
			})
			.finally(() => {
				setNotificationLoading(false);
			});
	};

	let unread_notifications = 0;
	let finalNotificationList = [];
	let data = {};

	if (myNotifications) {
		unread_notifications = myNotifications?.unread_notifications;
		data = myNotifications?.data;
		finalNotificationList = Object.values(data).sort((a, b) => b?.id - a?.id);
	}

	const handleSeeAll = () => {
		setOffcanvasStatus(false);
		navigate('/notification-all');
	};

	return (
		<>
			<div
				className='col-auto'
				style={{
					position: 'relative',
				}}>
				<Button
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...styledBtn}
					icon='Notifications'
					onClick={() => setOffcanvasStatus(true)}
					aria-label='Notifications'
				/>
				<p
					style={{
						position: 'absolute',
						top: -4,
						right: 8,
						background: 'orange',
						padding: '1px 4px',
						borderRadius: '16px',
						textAlign: 'center',
						color: 'white',
						fontWeight: 'bold',
						height: '20px',
					}}>
					{unread_notifications ?? 0}
				</p>
			</div>
			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>

					{unread_notifications > 0 && (
						<div id='offcanvasExampleLabelbutton'>
							<button
								disabled={notificationLoading}
								onClick={_handleMarkAsRead}
								className='btn btn-sm btn-warning'>
								{notificationLoading && <Spinner isSmall />} Mark all as read
							</button>
						</div>
					)}
				</OffCanvasHeader>
				<OffCanvasBody>
					{finalNotificationList.map((item, i) => {
						let icon = 'People';
						let color = 'bold';
						let url = ``;

						if (item?.notifiable_type == 'Member') {
							icon = 'People';
							url = `/customer-settings/members/${item.notifiable_id}`;
						}

						if (item?.notifiable_type == 'Vehicle') {
							icon = 'CarRepair';
							url = `/vehicles/${item.notifiable_id}`;
						}

						if (item?.notifiable_type == 'Auction') {
							icon = 'Construction';
							url = `/auctions/${item.notifiable_id}`;
						}

						if (item?.notifiable_type == 'CustomerMessage') {
							icon = 'MarkEmailRead';
							url = `/customers-queries/${item.notifiable_id}`;
						}

						if (item?.is_read) {
							color = 'normal';
						}

						return (
							<Alert
								style={{ cursor: 'pointer', fontWeight: color }}
								key={i}
								icon={icon}
								isLight
								color={'light'}
								className='flex-nowrap'
								onClick={() => _handleNotification(url, item)}>
								{item.message}
							</Alert>
						);
					})}
					{finalNotificationList.length > 0 && (
						<div style={{ display: 'flex', justifyContent: 'end', cursor: 'pointer' }}>
							<a className='text-right' onClick={handleSeeAll}>
								See all Notification
							</a>
						</div>
					)}
				</OffCanvasBody>
			</OffCanvas>
			<ToastContainer stacked />
		</>
	);
};

export default NotificationComponent;
