import Popovers from '../../../components/bootstrap/Popovers';
import Header, { HeaderRight } from '../../../layout/Header/Header';

const Header404 = () => {
	return (
		<Header>
			<HeaderRight>
				<Popovers title='Olfat Auction'>Olfat Auction</Popovers>
				<code>Olfat Auction</code>
			</HeaderRight>
		</Header>
	);
};

export default Header404;
