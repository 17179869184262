import { useContext } from 'react';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import Popovers from '../../../components/bootstrap/Popovers';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
// import useDeviceScreen from '../../../hooks/useDeviceScreen';
import NotificationComponent from '../../../components/notification/NotificationComponent';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
// import Navigation from '../../../layout/Navigation/Navigation';
// import { layoutMenu } from '../../../menu';

const DefaultHeader = () => {
	// const { width } = useDeviceScreen();
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};
	return (
		<Header>
			<HeaderLeft>
				{/* <Navigation
					menu={{ ...layoutMenu }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/> */}
				Olfat Auction
			</HeaderLeft>
			<HeaderRight>
				<div className='row g-3 align-items-center'>
					{/* Dark Mode */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Dark / Light mode'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								aria-label='Toggle fullscreen'
								data-tour='dark-mode'
							/>
						</Popovers>
					</div>
					{/* Full Screen */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Fullscreen'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
								onClick={() => setFullScreenStatus(!fullScreenStatus)}
								aria-label='Toggle dark mode'
							/>
						</Popovers>
					</div>
					{/*	Notifications */}
					<NotificationComponent darkModeStatus={darkModeStatus} />
				</div>
			</HeaderRight>
		</Header>
	);
};

export default DefaultHeader;
